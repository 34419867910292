import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh revoke`}</strong>{` -- revoke a SSH certificate using the SSH CA`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh revoke <serial-number>
[--token=<token>]  [--issuer=<name>] [--set=<key=value>]
[--set-file=<file>] [--password-file=<file>] [--reason=<string>]
[--reasonCode=<code>] [--sshpop-cert=<file>] [--sshpop-key=<key>]
[--offline] [--ca-config=<file>] [--ca-url=<uri>] [--root=<file>]
[--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh revoke`}</strong>{` command revokes an SSH Certificate
using `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates"
      }}>{`step certificates`}</a>{`.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`serial-number`}</inlineCode>{`
The serial number of the SSH certificate to revoke.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--token`}</strong>{`=`}<inlineCode parentName="p">{`token`}</inlineCode>{`
The one-time `}<inlineCode parentName="p">{`token`}</inlineCode>{` used to authenticate with the CA in order to create the
certificate.`}</p>
    <p><strong parentName="p">{`--provisioner-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use.`}</p>
    <p><strong parentName="p">{`--set`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` pair with template data variables. Use the `}<strong parentName="p">{`--set`}</strong>{` flag multiple times to add multiple variables.`}</p>
    <p><strong parentName="p">{`--set-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The JSON `}<inlineCode parentName="p">{`file`}</inlineCode>{` with the template data variables.`}</p>
    <p><strong parentName="p">{`--sshpop-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'sshpop' header of a JWT.`}</p>
    <p><strong parentName="p">{`--sshpop-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'sshpop' header.`}</p>
    <p><strong parentName="p">{`--reason`}</strong>{`=`}<inlineCode parentName="p">{`string`}</inlineCode>{`
The `}<inlineCode parentName="p">{`string`}</inlineCode>{` representing the reason for which the cert is being revoked.`}</p>
    <p><strong parentName="p">{`--reasonCode`}</strong>{`=`}<inlineCode parentName="p">{`reasonCode`}</inlineCode>{`
The `}<inlineCode parentName="p">{`reasonCode`}</inlineCode>{` specifies the reason for revocation - chose from a list of
common revocation reasons. If unset, the default is Unspecified.`}</p>
    <p><inlineCode parentName="p">{`reasonCode`}</inlineCode>{` can be a number from 0-9 or a case insensitive string matching
one of the following options:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Unspecified`}</strong>{`: No reason given (Default -- reasonCode=0).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`KeyCompromise`}</strong>{`: The key is believed to have been compromised (reasonCode=1).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`CACompromise`}</strong>{`: The issuing Certificate Authority itself has been compromised (reasonCode=2).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`AffiliationChanged`}</strong>{`: The certificate contained affiliation information, for example, it may
have been an EV certificate and the associated business is no longer owned by
the same entity (reasonCode=3).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Superseded`}</strong>{`: The certificate is being replaced (reasonCode=4).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`CessationOfOperation`}</strong>{`: If a CA is decommissioned, no longer to be used, the CA's certificate
should be revoked with this reason code. Do not revoke the CA's certificate if
the CA no longer issues new certificates, yet still publishes CRLs for the
currently issued certificates (reasonCode=5).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`CertificateHold`}</strong>{`: A temporary revocation that indicates that a CA will not vouch for a
certificate at a specific point in time. Once a certificate is revoked with a
CertificateHold reason code, the certificate can then be revoked with another
Reason Code, or unrevoked and returned to use (reasonCode=6).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RemoveFromCRL`}</strong>{`: If a certificate is revoked with the CertificateHold reason code, it is
possible to "unrevoke" a certificate. The unrevoking process still lists the
certificate in the CRL, but with the reason code set to RemoveFromCRL.
Note: This is specific to the CertificateHold reason and is only used in DeltaCRLs
(reasonCode=8).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PrivilegeWithdrawn`}</strong>{`: The right to represent the given entity was revoked for some reason
(reasonCode=9).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`AACompromise`}</strong>{`: It is known or suspected that aspects of the AA validated in the
attribute certificate have been compromised (reasonCode=10).`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`revoke an ssh certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh revoke 3997477584487736496
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      